@import '../../style/themes/index';
@import '../../style/mixins/index';

@timeline-prefix-cls: ~'@{ant-prefix}-timeline';

.@{timeline-prefix-cls} {
  .reset-component;

  margin: 0;
  padding: 0;
  list-style: none;

  &-item {
    position: relative;
    margin: 0;
    padding: 0 0 20px;
    font-size: @font-size-base;
    list-style: none;

    &-tail {
      position: absolute;
      top: 10px;
      left: 4px;
      height: calc(100% - 10px);
      border-left: @timeline-width solid @timeline-color;
    }

    &-pending &-head {
      font-size: @font-size-sm;
      background-color: transparent;
    }

    &-pending &-tail {
      display: none;
    }

    &-head {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: @timeline-dot-bg;
      border: @timeline-dot-border-width solid transparent;
      border-radius: 100px;

      &-blue {
        color: @primary-color;
        border-color: @primary-color;
      }

      &-red {
        color: @error-color;
        border-color: @error-color;
      }

      &-green {
        color: @success-color;
        border-color: @success-color;
      }

      &-gray {
        color: @disabled-color;
        border-color: @disabled-color;
      }
    }

    &-head-custom {
      position: absolute;
      top: 5.5px;
      left: 5px;
      width: auto;
      height: auto;
      margin-top: 0;
      padding: 3px 1px;
      line-height: 1;
      text-align: center;
      border: 0;
      border-radius: 0;
      transform: translate(-50%, -50%);
    }

    &-content {
      position: relative;
      top: -(@font-size-base * @line-height-base - @font-size-base) + 1px;
      margin: 0 0 0 18px;
      word-break: break-word;
    }

    &-last {
      > .@{timeline-prefix-cls}-item-tail {
        display: none;
      }
      > .@{timeline-prefix-cls}-item-content {
        min-height: 48px;
      }
    }
  }

  &.@{timeline-prefix-cls}-alternate,
  &.@{timeline-prefix-cls}-right {
    .@{timeline-prefix-cls}-item {
      &-tail,
      &-head,
      &-head-custom {
        left: 50%;
      }

      &-head {
        margin-left: -4px;
        &-custom {
          margin-left: 1px;
        }
      }

      &-left {
        .@{timeline-prefix-cls}-item-content {
          left: calc(50% - 4px);
          width: calc(50% - 14px);
          text-align: left;
        }
      }

      &-right {
        .@{timeline-prefix-cls}-item-content {
          width: calc(50% - 12px);
          margin: 0;
          text-align: right;
        }
      }
    }
  }

  &.@{timeline-prefix-cls}-right {
    .@{timeline-prefix-cls}-item-right {
      .@{timeline-prefix-cls}-item-tail,
      .@{timeline-prefix-cls}-item-head,
      .@{timeline-prefix-cls}-item-head-custom {
        // stylelint-disable-next-line function-calc-no-invalid
        left: calc(100% - 4px - @timeline-width);
      }
      .@{timeline-prefix-cls}-item-content {
        width: calc(100% - 18px);
      }
    }
  }

  &&-pending &-item-last &-item-tail {
    display: block;
    height: calc(100% - 14px);
    border-left: 2px dotted @timeline-color;
  }

  &&-reverse &-item-last &-item-tail {
    display: none;
  }

  &&-reverse &-item-pending {
    .@{timeline-prefix-cls}-item-tail {
      top: 15px;
      display: block;
      height: calc(100% - 15px);
      border-left: 2px dotted @timeline-color;
    }
    .@{timeline-prefix-cls}-item-content {
      min-height: 48px;
    }
  }
}

@primary-color: #008EFE;@success-color: #27AE60;@error-color: #EB5757;@highlight-color: #EB5757;@warning-color: #E69900;@black: #333;@body-background: #F2F5F7;@font-family: Roboto, sans-serif;@text-color: #333;@text-color-secondary: #5A5A5A;@heading-color: #333;@border-radius-base: 4px;@border-radius-sm: 4px;@layout-header-background: #F2F5F7;@layout-header-height: 82px;@layout-header-padding: 0 12px;@btn-height-base: 42px;@btn-height-lg: 42px;@btn-padding-base: 0 28px;@menu-inline-toplevel-item-height: 44px;@menu-item-height: 44px;@menu-item-active-bg: rgba(0, 142, 254, 0.2);@menu-item-active-border-width: 0;@menu-icon-size: 18px;@menu-item-color: #595A5A;@menu-item-vertical-margin: 0;@control-padding-horizontal: 16px;@input-height-base: 42px;@input-placeholder-color: #999;@typography-title-font-weight: 400;@typography-title-margin-bottom: 16px;